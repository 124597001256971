import React from "react";
import BlogIndexSummaryTiles from "../BlogIndexSummaryTiles";
import BlogIndexSmallCategoryItems from "../BlogIndexSmallCategoryItems";

export default (props) => {
  const { navigateOnFiltering, postContent, searchResults, filterBlogPostsUsing, blogPostCategories } = props;

  return (
    <div className="flex flex-wrap overflow-hidden">
      <div className="w-full overflow-hidden bg-blue-100">
        <BlogIndexSmallCategoryItems navigateOnFiltering={navigateOnFiltering} blogPostCategories={blogPostCategories} searchHandler={filterBlogPostsUsing} />
      </div>

      <div className="w-full overflow-hidden">
        <div className="">
          <div className="lg:w-3/4">{postContent ?? <BlogIndexSummaryTiles showReadMoreLink={true} blogPostData={searchResults} />}</div>
        </div>
      </div>
    </div>
  );
};
