import React from "react";
import BlogIndexNewsLetterForm from "../BlogIndexNewsLetterForm";
import BlogIndexCategoryItems from "../BlogIndexCategoryItems";
import BlogIndexSummaryTiles from "../BlogIndexSummaryTiles";
import BlogIndexSearchForm from "../BlogIndexSearchForm";
import BlogIndexPopularArticles from "../BlogIndexPopularArticles";
import BlogIndexHSplitter from "../BlogIndexHSplitter";
import BlogIndexSmartPricesSection from "../BlogIndexSmartPricesSection";
import BlogIndexArchiveItems from "../BlogIndexArchiveItems";
import BlogPostContent from "../BlogPostContent";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { navigateOnFiltering, postContent, searchResults, filterBlogPostsUsing, blogPostCategories, blogPostData, blogPostArchiveData } = props;

  return (
    <div className="flex">
      <div className="sticky top-0 w-64 h-full px-0 py-10 mx-0 border-r border-blue-200">
        <BlogIndexCategoryItems key={uuidv4()} navigateOnFiltering={navigateOnFiltering} blogPostCategories={blogPostCategories} searchHandler={filterBlogPostsUsing} />
        <BlogIndexNewsLetterForm key={uuidv4()} />
      </div>
      <div className="flex flex-1 ">
        <div className="w-3/4">{postContent ? <BlogPostContent postContent={postContent} /> : <BlogIndexSummaryTiles showReadMoreLink={false} blogPostData={searchResults} />}</div>
        <div className="w-1/4 px-2 py-10 bg-blue-100">
          <BlogIndexSearchForm navigateOnFiltering={navigateOnFiltering} searchHandler={filterBlogPostsUsing} />
          <BlogIndexPopularArticles blogPostData={blogPostData} />
          <BlogIndexHSplitter />
          <BlogIndexSmartPricesSection />
          <BlogIndexHSplitter />
          <BlogIndexArchiveItems navigateOnFiltering={navigateOnFiltering} blogPostArchiveData={blogPostArchiveData} searchHandler={filterBlogPostsUsing} />
        </div>
      </div>
    </div>
  );
};
