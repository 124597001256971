import React from "react";
import { Link } from "gatsby";
import CoffeeGirl from "../../../svg/womandrinkingcoffee.svg";

export default () => {
  return (
    <>
      <CoffeeGirl className="w-10/12 mx-auto " />
      <p className="">Browse the best deals for the utilities and services in your area</p>
      <div className="py-4 mb-2 text-center">
        <Link
          to="/"
          className="w-1/2 h-10 px-4 py-3 my-2 mb-2 text-gray-500 bg-yellow-500 border border-yellow-500 rounded hover:shadow-xl hover:border-blue-500 hover:bg-gray-500 hover:text-yellow-500"
        >
          Get Started
        </Link>
      </div>
    </>
  );
};
