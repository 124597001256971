import React from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { categoryData, searchHandler, navigateOnFiltering } = props;
  const itemKey = uuidv4();

  return (
    <>
      <li key={itemKey} className="inline-block">
        {navigateOnFiltering ? (
          <Link to="/blog" state={{ searchParams: { searchContext: "category-search", searchTerm: categoryData.category } }}>
            <p className="text=gray-500 hover:text-gray-500 hover:underline">
              <span>{categoryData.category}</span>
              <span className="ml-1 text-blue-500">({categoryData.numberOfPosts})</span>
            </p>
          </Link>
        ) : (
          <button className="text=gray-500 hover:text-gray-500 hover:underline" onClick={() => searchHandler({ searchContext: "category-search", searchTerm: categoryData.category })}>
            <p className="text=gray-500 hover:text-gray-500 hover:underline">
              <span>{categoryData.category}</span>
              <span className="ml-1 text-blue-500">({categoryData.numberOfPosts})</span>
            </p>
          </button>
        )}
      </li>
    </>
  );
};
