import React from "react";
import BlogIndexSmallCategoryItem from "../BlogIndexSmallCategoryItem";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { blogPostCategories, searchHandler, navigateOnFiltering } = props;

  return (
    <div className="bg-blue-100">
      <p className="text-blue-500 ">Categories</p>
      <ul className="text-xs text-gray-500 bg-blue-100 ">
        {blogPostCategories.map((c) => (
          <BlogIndexSmallCategoryItem key={uuidv4()} navigateOnFiltering={navigateOnFiltering} categoryData={c} searchHandler={searchHandler} />
        ))}
        <li key={uuidv4()} className="h-10 px-0 pt-2 list-none border-b border-blue-200 last:border-b-0">
          <hr className="border-t border-blue-200" />
          {navigateOnFiltering ? (
            <Link to="/blog" className="cursor-pointer" state={{ searchParams: { searchContext: "no-filter", searchTerm: "" } }}>
              <p className="text-gray-500 hover:text-blue-500 hover:underline">Show All</p>
            </Link>
          ) : (
            <button onClick={() => searchHandler({ searchContext: "no-filter", searchTerm: "" })} className="">
              <p className="text-gray-500 cursor-pointer hover:text-blue-500 hover:underline">Show All</p>
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};
