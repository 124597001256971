import React from "react";
import * as blogService from "../../services/BlogService";
import BlogIndexPopularArticle from "../BlogIndexPopularArticle";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { blogPostData } = props;
  const filteredPopularArticles = blogService.getFilteredPopularArticlesUsing(blogPostData);

  return (
    <>
      <p className="pt-4 text-blue-500">Popular articles</p>
      <ul className="px-0 pt-0 text-gray-500">
        {filteredPopularArticles.map((a) => (
          <BlogIndexPopularArticle key={uuidv4()} articleData={a} />
        ))}
      </ul>
    </>
  );
};
