import React from "react";
import BlogIndexArchiveItem from "../BlogIndexArchiveItem";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { blogPostArchiveData, searchHandler, navigateOnFiltering } = props;
  const itemKey = uuidv4();

  return (
    <>
      <p className="pt-6 text-blue-500">Archives</p>
      <ul className="px-0 text-gray-500">
        {blogPostArchiveData.map((a) => (
          <BlogIndexArchiveItem key={uuidv4()} navigateOnFiltering={navigateOnFiltering} archiveData={a} searchHandler={searchHandler} />
        ))}
        <li key={itemKey} className="w-11/12 h-10 px-0 py-0 align-middle list-none border-b border-gray-100 last:border-b-0">
          {navigateOnFiltering ? (
            <Link to="/blog" className="cursor-pointer" state={{ searchParams: { searchContext: "no-filter", searchTerm: "" } }}>
              <p className="text-blue-500 hover:text-blue-500 hover:underline">Show All</p>
            </Link>
          ) : (
            <button onClick={() => searchHandler({ searchContext: "no-filter", searchTerm: "" })} className="">
              <p className="text-blue-500 hover:text-blue-500 hover:underline">Show All</p>
            </button>
          )}
        </li>
      </ul>
    </>
  );
};
