import React, { useContext } from "react";
import View from "../View";
import BlogIndexLargeView from "../BlogIndexLargeView";
import BlogIndexSmallView from "../BlogIndexSmallView";
import * as blogService from "../../services/BlogService";
import BlogIndexSmallNewsletterForm from "../BlogIndexSmallNewsletterForm";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { window } from "browser-monads";

import useBlogData from "../../hooks/useBlogData";

export default (props) => {
  const windowSize = useContext(WindowSizeContext);
  const blogData = useBlogData();
  const navigateOnFiltering = props.content;
  const { blogPostData, blogPostCategories, blogPostArchiveData } = blogData;

  const initialSearchParams = blogService.getInitialSearchParamsUsing(window);
  const [searchParams, setSearchParams] = React.useState(initialSearchParams);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isLargeScreenView, setIsLargeScreenView] = React.useState(windowSize.windowSize);
  const [postData] = React.useState(blogPostData);

  const filterBlogPostsUsing = (searchParams) => {
    setSearchParams(searchParams);
  };

  React.useEffect(() => {
    const results = blogService.getFilteredBlogPostDataUsing(postData, searchParams);
    setSearchResults(results);

    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [postData, searchParams, windowSize.isLargeScreenView]);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <View>
      <section className="">
        <div className="container px-2 pt-10 pb-8 mx-auto">
          {isLargeScreenView ? (
            <BlogIndexLargeView
              navigateOnFiltering={navigateOnFiltering}
              postContent={props.content}
              searchResults={searchResults}
              filterBlogPostsUsing={filterBlogPostsUsing}
              blogPostCategories={blogPostCategories}
              blogPostData={blogPostData}
              blogPostArchiveData={blogPostArchiveData}
            />
          ) : (
            <>
              <BlogIndexSmallView
                navigateOnFiltering={navigateOnFiltering}
                postContent={props.content}
                searchResults={searchResults}
                filterBlogPostsUsing={filterBlogPostsUsing}
                blogPostCategories={blogPostCategories}
                blogPostData={blogPostData}
                blogPostArchiveData={blogPostArchiveData}
              />
            </>
          )}
        </div>
      </section>
      <section className={`${isLargeScreenView ? `hidden` : `block`}`}>
        <div className="w-full px-0 mx-0 overflow-hidden bg-yellow-500 border-yellow-500">
          <div className="container mx-auto">
            <BlogIndexSmallNewsletterForm />
          </div>
        </div>
      </section>
    </View>
  );
};
