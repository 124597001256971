import React from "react";
import BlogIndex from "../components/BlogIndex";
import Layout from "../components/Layout";

export default () => {
  return (
    <Layout>
      <BlogIndex />
    </Layout>
  );
};
