import React from "react";
import BlogIndexSummaryTile from "../BlogIndexSummaryTile";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { blogPostData, showReadMoreLink } = props;

  return (
    <>
      <ul className="text-gray-500 list-none lg:px-2 ">
        {blogPostData.map((post) => (
          <BlogIndexSummaryTile key={uuidv4()} showReadMoreLink={showReadMoreLink} articleData={post} />
        ))}
      </ul>
    </>
  );
};
