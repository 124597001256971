import React from "react";
import { Input } from "antd";
import { navigate } from "gatsby";

export default (props) => {
  const { Search } = Input;
  const { searchHandler, navigateOnFiltering } = props;

  const navigateAndSearch = (searchTerm) => {
    navigate("/blog", { state: { searchParams: { searchContext: "text-search", searchTerm: searchTerm } } });
  };

  return (
    <div className="">
      <Search
        allowClear={true}
        placeholder="Type a keyword to search"
        onSearch={(searchTerm) => (navigateOnFiltering ? navigateAndSearch(searchTerm) : searchHandler({ searchContext: "text-search", searchTerm: searchTerm }))}
      />
    </div>
  );
};
